
/* Graph */
.graph {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: white;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
