.amount-backdrop {
  background-color: var(--color-iceberg);
  width: 100%;
  height: 148px;
  position: absolute;
}

.amount-banner {
  padding: 24px 24px 0 24px;
  z-index: 1;
}

.amount-label {
  color: var(--color-rhino-60);
  margin-bottom: -1px;
}

.amount {
  font-size: 36px;
  color: var(--color-rhino);
  margin-bottom: 8px;
}

.fee-notice {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  height: 13px;
  color: var(--color-rhino-50);
}

.mode-toggle {
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: 10px;
}

.mode-toggle-button {
  width: 132px;
  font-size: 16px;
  border: none;
}

.transaction-button {
  background: transparent;
  border-radius: unset !important;
  font-weight: bold
}

.selected {
  border-bottom: 6px solid var(--color-caribbean-green);
}

.offer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 45px;
  padding: 0 24px;
}

.display-loading {
  padding: 50px;
  display: flex;
  justify-content: center;
}

.input-row {
  display: flex;
  width: 100%;
}

.form-label {
  font-family: "MPLUS1p";
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--color-rhino-60);
}

.form-input {
  padding-top: 4px;
  padding-bottom: 10px;
  margin-bottom: 4px;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid var(--color-rhino-50);
  background-color: var(--color-alabaster);
  color: var(--color-rhino);
  width: 100%;
  flex: 3;
}

.form-input::placeholder {
  color: var(--color-rhino-35);
}

.form-input-error {
  composes: form-input;
  border-bottom: 1px solid #EE4266;
}

.hash-and-nick {
  display: flex;
  margin-left: 10px;
  flex: 1;
  align-items: center;
}

.hash-icon {
  margin-right: 5px;
}

.nickname {
  display: block;
  color: var(--color-rhino);
  font-weight: normal;
  margin-bottom: 8px;
}

.number-input {
  composes: form-input;
  text-align: right;
}

.readonly-input {
  composes: number-input;
  border: none;
  background-color: var(--color-alto);
}

.hf {
  composes: form-label;
  text-align: left;
}

.notes-input {
  border: 1px solid var(--color-gray);
  font-family: "Roboto";
  font-size: 16px;
  width: 340px;
  height: 100px;
  align-self: center;
  margin-top: 50px;
  width: 100%;
}

.total {
  font-family: "MPLUS1p";
  font-size: 18px;
  color: var(--color-rhino);
  margin-top: 14px;
  margin-bottom: 32px;
}

.send-button {
  width: 192px;
  height: 42px;
  font-size: 16px;
  box-shadow: 0px 2.5px 10px rgba(44, 64, 90, 0.15);
  border: none;
  align-self: center;
}

.disabled {
  background-color: var(--color-iceberg);
}

.recent-counterparties {
  margin-top: 40px;
}

.error-text {
  font-family: 'MPLUS1p', sans-serif;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: rgba(44, 64, 90, 0.5);
}
