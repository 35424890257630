.modal-message {
  display: flex;
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  color: var(--color-rhino-60);
  margin-top: -10px;
  margin-bottom: 40px;
}

.modal-checkbox {
  margin: 0 auto;
}

.label {
  display: flex;
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  color: var(--color-rhino-60);
}
