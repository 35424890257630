.recent-counterparties {
  background-color: white;
  height: 100%;
}

.header {
  background-color: #ddd;
  padding: 0 24px;
  margin: 0;
  font-size: 10px;
  color: var(--color-rhino-50);
  background-color: var(--color-rhino-10);
  height: 24px;
  display: flex;
  align-items: center;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.no-peers {
  padding-top: 80px;
  text-align: center;
  height: 100%;
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  color: var(--color-rhino-60);
}

/* AgentRow */

.agent-row {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  border-top: 1px solid var(--color-athens-gray);
  border-bottom: 1px solid var(--color-athens-gray);
}

.selected {
  background-color: var(--color-rhino-02);
}

.avatar {
  margin-right: 30px
}

.name-and-id {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.name {
  color: var(--color-rhino-80);
  font-size: 14px;
}

.id {
  color: var(--color-rhino-50);
  font-style: italic;
}

.selected-indicator {
  margin-left: auto;
  color: var(--color-caribbean-green);
  font-size: 12px;
}
