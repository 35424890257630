/* big-and-bold is an example. It's currently used in StyleDemo only.  */
.big-and-bold {
  font-size: 20px;
  font-weight: 900;
}

h1, h2, h3, h4, h5 {
  margin-block-start: 0.33em;
  margin-block-end: 0.33em;
}

h1 {
  font-family: "MPLUS1p";
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
}

h2 {
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
}

h3 {
  font-family: "MPLUS1p";
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}

h4 {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0.63em;
}
