.flash-message {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  background-color: var(--color-caribbean-green);
  opacity: 0.9;
  padding: 10px;
  margin: 0 5px;
  align-items: center;
  align-self: center;
  border-radius: 4px;
  z-index: 30;
  color: white;
}

.message {
  margin: 0 auto;
  text-align: center;
}

.close-button {
  font-size: 20px;
  padding: 5px 10px;
  border: none;
  color: white;
  background: transparent;
}