.button {
  font-family: 'MPLUS1p';
  font-size: 13px;
  line-height: 15px;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px 15px;
}

.white {
  background: white;
  border: 1px solid var(--color-caribbean-green);
  color: var(--color-caribbean-green);
}

.red-on-white {
  background: white;
  border: 1px solid var(--color-amaranth);
  color: var(--color-amaranth);
}

.green {
  color: white;
  border: 1px solid var(--color-caribbean-green);
  background: var(--color-caribbean-green);
}

.disabled {
  opacity: 0.6;
}
