.header {
  background-color: var(--color-iceberg);
  padding: 38px 32px 0 32px;
}

.balance-label {
  color: var(--color-rhino-80);
  margin-bottom: -1px;
}

.balance-amount {
  font-family: "MPLUS1p";
  font-size: 36px;
  font-weight: bold;
  color: var(--color-rhino);
  margin-bottom: 45px;
}

.transactions-empty {
  border-radius: 4px;
  background-color: white;
  height: 120px;
  padding-top: 47px;
  margin: 36px 30px 0px 30px;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.transactions-empty-text {
  font-family: "MPLUS1p";
  font-size: 18px;
  text-align: center;
  color: var(--color-rhino-60);
  margin-bottom: 44px;
}

.plus-icon {
  align-self: center;
}

.transactions {
  background-color: white;
}

/* FilterButtons */

.filter-buttons {
  display: flex;
  justify-content: space-between;
}

.filter-button {
  font-family: "MPLUS1p";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: var(--color-rhino-50);
  padding-bottom: 7px;
  cursor: pointer;
}

.selected {
  border-bottom: 2px solid var(--color-caribbean-green);
  font-weight: bold;
  color: var(--color-rhino);
}

/* TransactionPartition */
.partition-label {
  color: var(--color-rhino-50);
  background-color: var(--color-rhino-10);
  margin: 0;
  padding: 0 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.partition-loading {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
}

/* TransactionRow */
.transaction-row {
  display: flex;
  padding: 0 24px;
  align-items: center;
  height: 60px;
  cursor: default;
}

.not-first-row {
  border-top: 2px solid var(--color-athens-gray);
}

.avatar {
  margin-right: 20px;
}

.name-and-notes {

}

.name {
  font-family: "MPLUS1p";
  font-size: 14px;
  line-height: 19px;
  color: var(--color-rhino-80);
}

.notes {
  font-style: italic;
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--color-rhino-50);
}

.amount-and-balance {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.amount {
  font-size: 14px;
  line-height: 19px;
  color: var(--color-rhino-80);
}

.transaction-balance {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: var(--color-rhino-50);
}

.pending-style {
  color: var(--color-rhino-50);
  font-style: italic;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
 }

 .highlightGreen {
   background-color: #CFF3EC;
 }

 .highlightRed {
   background-color: #FCE3E8;
 }

/* CancelButton */
.cancel-button {
  color: white;
  border-radius: 50%;
  background-color: var(--color-amaranth);
  font-weight: bold ;
  width: 22px;
  height: 20px;
  padding-bottom: 2px;
  text-align: center;
  margin-left: 10px;
  border: none;
  cursor: pointer
}

/* Modal */
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-text {
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-rhino-60);
  margin-bottom: 23px;
}

.modal-buttons {
  display: flex;
}

.modal-button {
  width: 130px;
  height: 42px;
  font-family: "MPLUS1p";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: none;
}

.modal-button-no {
  composes: modal-button;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--color-iceberg);
  color: var(--color-rhino-60);
}

.modal-button-yes {
  composes: modal-button;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Education Modal */

.message {
  margin: 0 auto;
}

.message-paragraph {
  margin: 20px 0px;
}
