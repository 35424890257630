.null-state-body {
  font-family: MPLUS1p;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: rgba(44, 64, 90, 0.6);
  margin: 20px;
  margin-top: 30px;
}

.null-state-message {
  display: block;
  width: 200px;
  margin: 0 auto;
}

.wide {
  display: block;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}
