.inputs {
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center
}

.price-input {
  max-width: 5.5em; /* Up to 5 digits */
}

.connecting-label {
  margin: 3px 14px 5px;
  font-size: 14px;
}

.units-dropdown {
  border: 1px solid lightgray;
  border-radius: 0;
  padding: 10px;
  background-color: white;
  font-size: 18px;
}

.save-button {
  margin-top: auto;
}
