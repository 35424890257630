/* PlusInDiscIcon */

.disc-icon {
  border-radius: 50%;
  margin-right: 8px;
  width: 21px;
  height: 21px;
}

.plus-icon {
  width: 26px;
  height: 16px;
  margin-top: 5px;
  margin-left: 5px;
}