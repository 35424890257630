.image {
  padding: 0.25rem;
  background-color: transparent;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
  border-radius: 50%;
}

.default-identicon {
  border-radius: 50%;
  background-color: grey;
  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  max-width: 85px;
  max-height: 85px;
  margin: 0px 10px;
}

.big-icon {
  composes: default-identicon;
  width: 48px;
  height: 48px;
}

.small-icon {
  composes: default-identicon;
  width: 32px;
  height: 32px;
}
