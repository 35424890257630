.dashboard {
  display: flex;
  flex-shrink: 1;
}

.dashboard-wide {
  composes: dashboard;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard-narrow {
  composes: dashboard;
  flex-direction: column;
  flex-grow: 1;
}

.hosting-row {
  display: flex;
  font-family: "MPLUS1p";
  font-size: 14px;
  line-height: 19px;
  color: var(--color-rhino);
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
}

.hosting-row-expanded {
  composes: hosting-row;
}

.hosting-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.up-arrow {
  transform: rotate(270deg);
  margin-left: 7px;
}

.down-arrow {
  transform: rotate(90deg);
  margin-left: 7px;
}

.happ-list {
  position: relative;
  top: -6px;
  left: -44px;
  width: 200px;
}

.happ-name {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: var(--color-rhino);
  text-align: end;
}

.balance {
  font-size: 24px;
  line-height: 28px;
  margin-top: -8px;
  color: var(--color-rhino);
  font-family: "MPLUS1p";
  margin-bottom: 20px;
}


.pricing-section {
  color: var(--color-rhino);
  font-family: "MPLUS1p";
  background-color: var(--color-geyser);
  padding: 16px 21px 18px 21px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 -16px -16px -16px;
}

.pricing-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 6px;
}

.pricing-row {
  display: flex;
  margin-left: 2px;
  align-items: center;
  margin-bottom: 2px;
}

.pricing-type {
  font-size: 14px;
  line-height: 19px;
  width: 106px;
}

.price {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  width: 62px;
}

.pricing-unit {
  font-size: 14px;
  line-height: 19px;
}

.balance-label {
  font-family: "MPLUS1p";
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: var(--color-rhino);
  margin-top: 30px;
  margin-bottom: 10px;
}

.balance {
  font-family: "MPLUS1p";
  font-size: 24px;
  line-height: 28px;
  color: var(--color-rhino)
}

/* Card */

.card {
  background: rgba(220, 220, 220, 0.31);
  border: 1px solid var(--color-blue-lagoon);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 16px 16px 16px;
  position: relative;
  margin-bottom: 46px;
  text-decoration: none;
}

.card-wide {
  composes: card;
  width: 325px;
  margin-right: 46px;
}

.card-narrow {
  composes: card;
}

.card-title {
  font-size: 36px;
  line-height: 28px;
  font-weight: 500;
  color: var(--color-rhino);
  margin-top: 0px;
  margin-bottom: 16px;
}

.card-subtitle {
  color: var(--color-rhino-35);
}
