.button {
  font-size: 14px;
  background: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  max-width: 250px !important;
  margin: 0 auto;
  margin-top: 50px !important;
  padding: 10px;
}

.wide {
  display: block;
  width: 100%;
}

.plain {
  background-color: white;
  color: black;
}

.primary {
  background-color: var(--color-caribbean-green);
  color: #fff;
}

.secondary {
  background-color: var(--color-rhino);
}

.disabled {
  opacity: 0.6;
  cursor: none
}

.mini {
  padding: 0;
}

.toggle {
  border: none;
  background-color: transparent;
  margin: 5px;
  color: rgba(44, 64, 90, 0.5);
  font-family: 'MPLUS1p', sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  border-radius: 3px;
}

.toggle-selected {
  /* composes: toggle; */
  border: none;
  background-color: transparent;
  margin: 5px;
  font-family: 'MPLUS1p', sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  border-radius: 3px;

  color: #2C405A;
  padding-bottom: 5px;
  border-bottom: 2px solid #0DC39F
}
