.divider {
  height: 24px;
  background: rgba(44, 64, 90, 0.1);
  display: flex;
  align-items: center;
  font-family: "MPLUS1p";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--color-rhino-50);
  padding: 0 24px;
}
