@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MPLUS1p';
  src: local('MPLUS1p'), url(../assets/fonts/MPLUS1p-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: "MPLUS1p";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

html, body, #root {
  height: 100%;
}

table {
  border-collapse: collapse;
}
