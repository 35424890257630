.header-row {
  display: flex;
  align-items: center;
}

.header {
  color: var(--color-rhino);
  margin: 0px;
}

.pricing-link {
  display: flex;
  margin-left: auto;
  color: var(--color-cape-cod);
  font-size: 14px;
  line-height: 16px;
}

.gear-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.happ-list {
  padding-top: 20px;
}

.pricing-button {
  /* this margin-bottom value is to stop the button being pushed off the screen. there's a better way to do this */
  margin-top: auto;
}

/* HappRow */

.happ-row {
  display: flex;
  margin-bottom: 30px;
  text-decoration: none;
}

.details {
  flex-grow: 1;
  padding-top: 5px;
  padding-left: 16px;
}

.title {
  font-size: 20px;
  color: var(--color-rhino);
  margin-top: -2px;
}

.description {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-rhino-50);
  margin-bottom: 4px;
}

/* HappThumbnail */

.thumbnail {
  display: block;
  flex: 0 0 100px;
  height: 100px;
  box-shadow: 2px 4px 10px rgba(44, 64, 90, 0.1);
  border-radius: 4px;
}


/* HostButton */
.host-button {
  width: 100px;
}