.earnings-banner {
  margin-bottom: 16px;
}

.earnings-label {
  color: var(--color-rhino-80);
}

.earnings-amount {
  margin-top: 0px;
  color: var(--color-caribbean-green);
}

.day-buttons {
  display: flex;
  margin-bottom: 8px;
}

.day-button {
  height: 25px;
  margin: 0 2px 20px 2px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  flex: 1;
  border-radius: 3px;
}

.transaction-list {
  font-size: 12px;
  font-family: 'MPLUS1p';
  font-size: 14px;
  line-height: 18px;
  margin: 0px -24px;
}

.transaction-headers {
  height: 25px;
  color: var(--color-rhino-50);
  background-color: var(--color-rhino-10);
  text-align: left;
}

.transaction-headers > th {
  font-weight: normal;
}

.transaction-headers > th:first-child {
  padding-left: 24px;
}

/* TransactionRow */
.transaction-row {
  height: 45px;
  color: var(--color-rhino-80);
  background-color: white;
  border-top: 1px solid var(--color-ghost);
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}

.transaction-row > td:first-child {
  padding-left: 24px;
}

/* Graph */
.graph {
  height: 200px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: white;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
