/* color names come from http://chir.ag/projects/name-that-color/ */
/* this is useful for translating from rgba to hex https://www.hexcolortool.com/ */

:root {
  --color-tundora: #484848;
  --color-gray: #939393;
  --color-gallery: #EFEFEF;
  --color-alabaster: #F9F9F9;
  --color-alabaster-light: #FCFCFC;
  --color-cape-cod: #363D3C;
  --color-loblolly: #BFC5CD;
  --color-outerspace: #314843;
  --color-caribbean-green: #0DC39F;
  --color-limed-spruce: #39474A;
  --color-granny-apple-50: rgba(211, 245, 230, 0.5);  
  --color-iceberg: #CFF3EC;
  --color-rhino-02: rgba(44, 63, 89, 0.02);      
  --color-rhino-10: rgba(44, 63, 89, 0.10);      
  --color-rhino-35: rgba(44, 63, 89, 0.35);  
  --color-rhino-50: rgba(44, 63, 89, 0.50);    
  --color-rhino-60: rgba(44, 63, 89, 0.60);
  --color-rhino-80: rgba(44, 63, 89, 0.80);
  --color-rhino: #2C405A;
  --color-ghost: #CCD1D7;
  --color-amaranth: #EE4266;
  --color-blue-lagoon: #00838D;
  --color-havelock-blue: #41A1DC;
  --color-persimmon: #FE6847;
  --color-concrete: #F2F2F2;
  --color-athens-gray: #FAFBFC;
  --color-mountain-meadow: #23CC80;
  --color-ocean-green: #419f8e;
  --color-scampi: #6D5AAD;
  /* color-shocking used in StyleDemo only */
  --color-shocking: #E292C0;
  --color-geyser: #C6DBDD;
}
