.left-nav {
  min-width: 27px;
}

.header {
  display: flex;
  background-color: white;
  margin: 0px -24px 0px -24px;
  padding: 10px 24px 5px 24px;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.1);
}

.back-button {
  display: flex;
  align-items: center;
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  color: var(--color-rhino);
  text-decoration: none;
}

.back-text {
  margin-left: 5px;
}

.arrow-icon {
  transform: rotate(180deg);
}

.nickname {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: black;
}


.menu-button {
  border: none;
  padding: 5px;
  background-color: white
}

.menu-icon {
  width: 22px;
  height: 22px;
  background-color: white;
}

.title {
  font-size: 18px;
  line-height: 21px;
}

.settings-link {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 14px;
  position: relative;
  right: -16px;
}

.settings-link {
  font-family: 'MPLUS1p';
  font-size: 14px;
  line-height: 16px;
  color: var(--color-cape-cod);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-cape-cod);
}

.back-icon,
.gear-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 2px;
}
