.image {
    padding: 0.25rem;
    background-color: transparent;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: top;
    display: inline-block;
    border-radius: 50%;
  }