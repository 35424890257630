.drawer {
  position: fixed;
  top: 0;
  left: -280px;
  bottom: 0;
  z-index: 98;
}

.container {
  box-sizing: border-box;
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 22px 20px;
  background-color: var(--color-alabaster-light);
  transform: translateX(-10%);
  transition: transform .3s ease;
  box-shadow: 0px 9.5px 10px rgba(0, 0, 0, 0.5);
  will-change: transform;
  pointer-events: none;
}

.drawer--open .container {
  transform: translateX(100%);
  pointer-events: initial;
  /* overflow-y: scroll; */
}

.refresh-button {
  font-size: 13px;
  line-height: 15px;
  display: flex;
  height: 32px;
  box-shadow: 0px 2.5px 10px rgba(44, 64, 90, 0.15);
  margin-top: 25px;
  margin-bottom: 30px;
}

.refresh-loading {
  display: flex;
  align-self: center;
  margin: 15px;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  will-change: opacity;
  transition: opacity .3s ease;
  pointer-events: none;
}

.drawer--open .drawer-overlay {
  opacity: .5;
  pointer-events: initial;
}

.header {
  text-align: center;
  border-bottom: 1px solid var(--color-loblolly);
  padding-bottom: 20px;
}

.nickname {

}

.balance {

}

.settings-link {
  margin-top: 12px;
  font-family: 'MPLUS1p';
  font-size: 14px;
  line-height: 16px;
  color: var(--color-cape-cod);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-cape-cod);
}

.back-icon,
.gear-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-right: 2px;
}

.nav {
  color: #fff;
}

.active-link {
  background-color: #CFF3EC;
  padding: 0px 10px;
  border-radius: 5px;
}

/* Header */

.appName {
  font-weight: 700;
  font-size: 32px;
}

.avatar {
  margin: 12px 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
}

.header-account {
  display: block;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
}

.header-balance {
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.menu-button {
  width: 32px;
  top: -8px;
  right: 11px;
}

/* Nav */

.nav-list {
  padding: 0;
  list-style-type: none;
  margin: 15px 10px;
}

.nav-link {
  display: flex;
  padding: 10px 0;
  color: var(--color-outerspace);
  text-decoration: none;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.05px;
  font-weight: bold;
}

.nav-link:last-child {
  margin-bottom: 15px;
}

.back-link {
  border-top: 1px solid var(--color-loblolly);
  border-bottom: 1px solid var(--color-loblolly);
}

.admin-nav-link {
  composes: nav-link;
  margin: 15px 0px;
}

.alpha-info {
  position: relative;
  margin: 0 -32px 10px -32px;
  padding: 30px 12px;
  font-family: 'MPLUS1p';
  font-size: 14px;
  line-height: 16px;
  color: var(--color-rhino);
  background-color: var(--color-iceberg);
}

.alpha-flag {
  position: absolute;
  top: -16px;
  left: 0px;
}

.alpha-link {
  color: var(--color-rhino);
}

.btn-loading {
  filter: grayscale(60%);
}

/* Footer */

.footer {
  justify-self: flex-end;
  margin: auto 10px 0px 10px;
}

.footer-list {
  padding: 0;
  list-style-type: none;
}

.footer-list-item {
  padding: 4px 0px;
}

.footer-link {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-caribbean-green);
}

.version-info {
  font-family: 'MPLUS1p';
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  color: var(--color-rhino-50);
}

/* InboxBadge */

.inbox-badge {
  width: 20px;
  height: 20px;
  background-color: var(--color-persimmon);
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}
