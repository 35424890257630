/* Buttons & Icons */

.plus-icon {
  display: inline-flex;
  flex: 1;
  width: 21px;
  height: 21px;
  position: relative;
  top: 5px;
}

.plus-icon-mini {
  composes: plus-icon;
  margin-top: 20px;
}

.button-text {
  display: inline-flex;
  flex: 1;
  font-size: 16px;
  margin-left: 14px;
}

.new-transaction-button {
  font-size: 13px;
  line-height: 15px;
  display: flex;
  height: 32px;
  box-shadow: 0px 2.5px 10px rgba(44, 64, 90, 0.15);
  margin-bottom: 30px;
}

.plus-in-disc {
  margin-top: -3px;
  margin-left: -4px;
}

.view-button {
  font-family: "MPLUS1p";
  font-size: 16px;
  color: var(--color-rhino-50);
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.view-button-selected {
  composes: view-button;
  font-weight: bold;
  color: var(--color-rhino);
  border-bottom: 2px solid var(--color-caribbean-green);
}

.display-loading {
  padding: 50px;
  display: flex;
  justify-content: center;
}

/* Null State */

.null-state-message {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.null-add-icon {
  margin-top: 20px;
}

/* Transaction Row */

.transaction-list {
  margin: 0px;
}

.transaction-row {
  display: flex;
  height: 72px;
  align-items: center;
  border-bottom: 1px solid rgba(44, 64, 90, 0.1);
  font-size: 14px;
  padding: 0 14px;
  background-color: #FFFFFF;
  justify-content: space-between;
  /* width: 80px; */
  transition: transform 1s ease-in-out;
  cursor: default;
}

.transaction-row-drawer-open {
  padding-left: 4px;
  padding-right: 0px;
}

.balance {
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
  color: var(--color-gray);
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.transaction-row-loading {
  margin-right: 6px;
  margin-left: 6px;
}

/* Avatar and Description Cells */

.avatar {
  width: 50px;
  flex: .75;
  margin: 0px 10px;
  font-size: 12px;
  align-self: center;
}

.description-cell {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.counterparty {
  font-size: 14px;
  color: rgba(44, 64, 90, 0.8);
}

.story {
  color: rgba(44, 64, 90, 0.5);
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: lowercase;
}

.notes {
  font-style: italic;
  color: var(--color-gray);
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

/* AmountCell */

.amount-cell {
  display: flex;
  flex: 1.8;
  flex-direction: column;
}

.amount {
  flex: 1;
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
  font-size: 14px;
  line-height: 19px;
  word-break: keep-all;
  color: rgba(44, 64, 90, 0.8)
}

.credit {
  color: #0DC39F;
}

.debit {
  color: #EE4266;
}

.removed {
  text-decoration: line-through;
  color: rgba(44, 64, 90, 0.8);
  opacity: 0.8;
}

.annulled {
  opacity: 0.8;
}

.modal-note-text {
  composes: notes;
  text-transform: none;
}

.disabled {
 opacity: 0.8;
 pointer-events: none;
}

.highlightGreen {
  background-color: #CFF3EC;
}

.highlightRed {
  background-color: #FCE3E8;
}

.highlightYellow {
  background-color: #f6e3ab;
}

.inProcess {
  opacity: 0.7;
}

.alert-msg {
  composes: notes;
  margin-right: 20px;
  color: rgba(44, 64, 90, 0.8);;
}

/* ActionsCell */

.reveal-actions-button  {
  flex: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
  /* align-self: center; */
}

.forward-icon {
  width: 22px;
  height: 22px;
  background-color: transparent;
  opacity: 0.5;
  transition: opacity 300ms ease-out;
}

.actions {
  display: flex;
  flex-direction: row nowrap;
}

.drawer {
  flex: 1;
}

.drawer-close {
  display: flex;
  flex: 0 0 0;
  visibility: visible;
}

.drawer-close .forward-icon{
  visibility: visible;
}

.drawer-close .actions {
  display: none;
}


.action-button {
  border-radius: 0;
  border: 0;
  color: white;
  height: 72px;
  width: 64px;
  font-family: "MPLUS1p";
  font-size: 10px;
  text-align: center;
  text-transform: capitalize;
  word-break: keep-all;
}

.accept-button {
  composes: action-button;
  background-color: var(--color-mountain-meadow);
}

.reject-button {
  composes: action-button;
  background-color: var(--color-amaranth);
}

/* Education Modal */

.message {
  margin: 0 auto;
}

.message-paragraph {
  margin: 20px 0px;
}
