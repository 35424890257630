/* ConfirmationModal */

.counterparty-message {
  font-family: "MPLUS1p";
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--color-rhino-60);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.counterparty-loading {
  margin-left: 10px;
  margin-top: 8px;
}

.modal-amount {
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-rhino-60);
  padding: 50px 50px 25px;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-desktop {
  margin-left: 282px;
  margin-right: 2px;
}

.modal-message {
  font-family: "MPLUS1p";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-rhino-60);
  padding: 50px 50px 25px;
}

.decline-modal-message {
  composes: modal-message;
  padding: 50px 25px;
}

.modal-buttons {
  margin-bottom: 34px;
}

.modal-button {
  height: 42px;
  width: 132px;
  color: var(--color-caribbean-green);
  color: white;
  border: none;
}

.modal-button-no {
  composes: modal-button;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.modal-button-yes {
  composes: modal-button;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--color-ocean-green);
}

.modal-button-return-funds {
  composes: modal-button;
  font-size: 1rem;
  font-weight: bolder;
  width: 15rem;
}
