.primary-layout {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.wide {
  display: flex;
  min-height: 100%;
}

.narrow {
  padding: 0px 24px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.content-narrow {
  composes: content;
  padding-top: 40px;
}

.content-wide {
  composes: content;
  padding-top: 70px;
  margin-left: 78px;
  margin-right: 78px;
}

.menu-link {
  padding-bottom: 20px;
  cursor: pointer;
}

/* DesktopLayout */

.desktop-title {
  font-family: "MPLUS1p";
  font-size: 14px;
  line-height: 19px;
  color: black;
  margin-bottom: 20px;
}

/* Footer */

.wrapper {
  box-sizing: border-box;
  margin-top: auto;
  padding: 0px 24px;
}

.wrapper-narrow {
  composes: wrapper;
  font-size: 14px;
}

.wrapper-wide {
  composes: wrapper;
  font-size: 10px;
}

.container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-alabaster-light);
}

.container-wide {
  composes: container;
  width: 26%;
}

.footer {
  flex-shrink: 0;
  justify-self: flex-end;
  margin: 0px 10px;
}

.footer-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.footer-list-item {
  padding: 4px 0px;
}

.footer-link {
  text-decoration: none;
  line-height: 16px;
  color: var(--color-rhino-35)
}

.alpha-info {
  position: relative;
  margin: 0 -34px 0px -34px;
  padding: 32px 10px 8px 10px;
  font-family: 'MPLUS1p';
  line-height: 16px;
  color: var(--color-rhino);
  background-color: #d2f2f5;
}

.alpha-flag {
  position: absolute;
  z-index: 1;
}

.alpha-flag-banner {
  composes: alpha-flag;
  top: -16px;
  left: 0px;
}

.alpha-link {
  color: var(--color-rhino);
}

/* Sidebar */

.sidebar {
  background-color: var(--color-alabaster);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 186px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  font-family: "MPLUS1p";
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: black;
  margin-top: 22px;
  margin-bottom: 38px;
}

.settings-link {
  align-self: center;
  padding: 10px;
}

.device-name {
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.4px;
  color: black;
  margin-bottom: 62px;
}

.home-text {
  margin-left: -14px;
}

.home-link {
  display: flex;
  align-self: center;
  font-family: "MPLUS1p";
  font-size: 10px;
  line-height: 13px;
  text-decoration: none;
  letter-spacing: 0.4px;
  color: var(--color-rhino);
}
