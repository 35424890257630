.avatar {
  align-self: center;
  margin-bottom: 10px;
}

.title {
  font-family: 'MPLUS1p';
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: var(--color-rhino);
  margin-bottom: 40px;
}

.settings-section {
  padding-top: 36px;
}

.settings-section-narrow {
}

.settings-section-wide {
  margin: 0 -53px;
}

.version-header-row {
  display: flex;
}

.header-padding {
  padding-top: 18px;
  padding-bottom: 10px;
}

.settings-header {
  composes: header-padding;
  color: var(--color-outerspace);
  font-family: 'MPLUS1p';
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  flex: 1;
}

.update-available {
  composes: header-padding;
  font-family: 'MPLUS1p';
  font-size: 12px;
  line-height: 22px;
  color: var(--color-amaranth);
  flex: 1;
}

.reset-link {

}

/* SettingsRow */

.settings-row {
  display: flex;
  align-items: center;
  background-color: white;
  margin: 0px -24px;
  padding: 0px 24px;
  height: 46px;
  border-top: 1px solid var(--color-rhino-35);
}

.settings-row-bottom {
  composes: settings-row;
  border-bottom: 1px solid var(--color-rhino-35);
}

.settings-label {
  color: var(--color-rhino-50);
  font-family: 'MPLUS1p';
  font-size: 14px;
  flex: 1
}

.settings-value {
  color: var(--color-rhino);
  font-family: 'MPLUS1p';
  font-size: 14px;
  flex: 1
}

.device-name-button {
  display: flex;
  flex: 1
}

.device-name-input {
  color: var(--color-rhino);
  font-family: 'MPLUS1p';
  font-size: 14px;
  flex: 1;
}

.device-edit-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
}

.device-edit-button {
  font-size: 10px !important;
  line-height: 13px !important;
  padding: 5px 15px !important;
  text-transform: uppercase;
  height: 22px;
  margin-left: 10px;
}

.factory-reset-button {
  color: var(--color-amaranth);
  background-color: transparent;
  font-family: 'MPLUS1p';
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  border: none;
  padding-left: 0px !important;
}

.arrow-wrapper {
  margin-left: auto;
}

.field-error {
  font-size: .9em;
  font-weight: 500;
  color: #a00;
  margin: .3em 0 .0;
}

/* VersionUpdateButton */
.version-update-button {
  font-size: 10px !important;
  line-height: 13px !important;
  padding: 5px 1px !important;
  text-transform: uppercase;
  flex: 1
}
